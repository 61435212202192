import React from "react";
import "./DiaryBanner.css";
import Image from "../../../../images/DiaryBanner.webp";

export default function DIMainBanner() {
  return (
    <div>
      <div style={{ position: "relative", marginBottom: "-4px" }}>
        <img className="analystCoverage-home-image" alt="" src={Image} />
        <div className="analystCoverage-image-text-box">
          <div className="analystCoverage-image-background-text">
            <h1 className="analystCoverage-image-topic">Diary Products</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
