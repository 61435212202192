import React, { useEffect } from "react";
import "./DairySegment.css";
import Image from "../../../../images/Diary-table.png";
import Aos from "aos";
import "aos/dist/aos.css";
import "./AboutDiary.css";

const DairySegment = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div style={{ marginTop: "70px", marginBottom: "70px" }}>
      <center>
        <div>
          <h2
            className="dairy-heading"
            data-aos="fade-down"
            data-aos-duration="600"
          >
            Our Investment in the DAIRY Segment
          </h2>
        </div>
        <div className="dairy-container">
          {/* First Column */}
          <div className="dairy-column">
            <center data-aos="zoom-in" data-aos-duration="700">
              <img src={Image} alt="Dairy Processing" className="dairy-image" />

              <h2 className="dairy-title">
                DAIRY PROCESSING PLANT
              </h2>
            </center>
          </div>

          {/* Second Column */}
          <div
            className="dairy-column"
            data-aos="fade-left"
            data-aos-duration="700"
          >
            <table className="dairy-table">
              <tbody>
                <tr>
                  <td className="dairy-table-header">LOCATION</td>
                  <td className="dairy-table-header">CAPACITY</td>
                </tr>
                <tr>
                  <td className="dairy-table-cell" colSpan={2}>
                    State-of-the-art automated yoghurt production plant capacity
                    of handling 14,000 litres of raw milk per day, including a
                    milk heat treatment unit, and filler section.
                    <br />
                  </td>
                </tr>
                <tr>
                  <td className="dairy-table-cell">DAMBULLA</td>
                  <td className="dairy-table-cell">
                    <samp className="dairy-table-cell-p">200,000</samp> Set
                    Yoghurts Per Day
                  </td>
                </tr>
                <tr>
                  <td className="dairy-table-cell" colSpan={2}>
                    CERTIFICATIONS
                    <br />
                    <samp className="dairy-table-cell-p">
                      ISO 22000:2018,
                    </samp>{" "}
                    including GMP
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </center>
    </div>
  );
};

export default DairySegment;
