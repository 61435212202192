import React from "react";
import MainBanner from "../templates/OurSegments/AgriProduce/AgriDiary/DiaryBanner";
import AboutDiary from "../templates/OurSegments/AgriProduce/AgriDiary/AboutDiary";
import DairySegment from "../templates/OurSegments/AgriProduce/AgriDiary/DairySegment";

function AgriProduce() {
  return (
    <div>
      <MainBanner />
      <AboutDiary />
      <DairySegment />
    </div>
  );
}

export default AgriProduce;
