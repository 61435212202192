import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./AboutDiary.css";

export default function AboutDiary() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="agriProduce-layout-42">
        <div className="agriProduce-content2">
          <div
            className="agriProduce-column2"
            data-aos="fade-right"
            data-aos-duration="700"
          >
            <div className="agriProduce-heading">Diary Products</div>
          </div>
          <div className="agriProduce-column3">
            <div className="agriProduce-text">
              <p
                className="agriProduce-this-was-done"
                data-aos="fade-left"
                data-aos-duration="500"
              >
                Our dairy range comprising cultured dairy and fresh UHT milk is
                marketed under the iconic CIC Creamoo label which holds a
                commanding position in these respective market spaces. CIC
                Creamoo curd, yoghurt and fresh milk are all produced at our
                state-of-the-art, internationally accredited plant in Dambulla
                which has the capacity to process 14,000 litres of raw milk per
                day.
              </p>
              <p className="agriProduce-this-was-done">&nbsp;</p>
              <p
                className="agriProduce-this-was-done"
                data-aos="fade-left"
                data-aos-duration="700"
              >
                All our products are manufactured using the freshest milk, which
                is sourced responsibly through the network of exclusive farmers.
                These micro entrepreneurs who are often from remote regions of
                the Country, are supported by the CIC Group by way of technical
                and financial assistance. CIC Creamoo are made available to
                consumers through our island- wide distributor network.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
